import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import ApiService from '../../services/API';
import { TokenService, UserService } from '../../services/Storage';
import AuthService from '../../services/Session';

const SessionContext = createContext();

const userDefault = {
  identificationName: '',
  tradeName: '',
  responsibleName: '',
  email: '',
};

export const SessionProvider = (props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState({});
  const [loading, setLoading] = useState(true);

  function getUserAuthenticated() {
    const user = UserService.getUser();
    setUserAuthenticated(user);
  }

  function getUserInfo() {
    ApiService.get('/licensed/me').then(({ data }) => {
      const userData = {
        identificationName: data.data.affiliate.responsible_name,
        tradeName: data.data.affiliate.trade_name,
        responsibleName: data.data.affiliate.responsible_name,
        email: data.data.affiliate.email,
        affiliateId: data.data.affiliate.id,
      };

      setUserAuthenticated(userData);
      UserService.saveUser(userData);

      return data;
    });
  }

  useEffect(() => {
    try {
      const token = TokenService.getRefreshToken();
      if (token) {
        const { exp } = jwtDecode(token);
        if (exp > new Date().getTime() / 1000) {
          ApiService.setHeader();
          getUserAuthenticated();
          setIsAuthenticated(true);
        } else {
          signOut();
        }
      }
    } catch (error) {
      enqueueSnackbar(
        'Token de acesso inválido. Favor realizar o login novamente.',
        {
          variant: 'error',
        },
      );
    }
    setLoading(false);
  }, []);

  function signIn(email, password) {
    return AuthService.login(email, password).then((response) => {
      getUserAuthenticated();
      getUserInfo();
      setIsAuthenticated(true);
      return response;
    });
  }

  function signOut() {
    setUserAuthenticated(userDefault);
    AuthService.logout().finally(()=>{
      setIsAuthenticated(false);
      TokenService.removeToken();
      UserService.removeUser();
      ApiService.removeHeader();
    })
  }

  return (
    <SessionContext.Provider
      value={{ loading, isAuthenticated, userAuthenticated, signIn, signOut }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(SessionContext);
