const USER_KEY = '@TCAuthIndicator:user';

const UserStorage = {
  getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  },

  saveUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem(USER_KEY);
    // localStorage.removeItem(USER_ID);
  },

  // saveUserId(sub) {
  //   localStorage.setItem(USER_ID,sub);
  // },
  // getUserId() {
  //   return localStorage.getItem(USER_ID);
  // },
};

export default UserStorage;
