import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import App from './components/App/index';
import * as serviceWorker from './serviceWorker';

Sentry.init({
	dsn: "https://fa62a2eea0697fffb86c2f3517d52b05@o4504742388236288.ingest.sentry.io/4505805534593024",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
  });


ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
