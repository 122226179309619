import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Routes from '../../routes';
import theme from '../../theme';
import SnackbarProvider from './Snackbar';
import GlobalStyles from '../../styles';
import ApiService from '../../services/API';
import { SessionProvider } from '../../contexts/Session';

const apiHost = process.env.REACT_APP_UTILS_API ?? '';
ApiService.init(apiHost);
ApiService.mountReponseInterceptor();

function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Suspense fallback={<></>}>
          <SnackbarProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SessionProvider>
                <Routes />
              </SessionProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </Suspense>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
